@use 'tailwind-preflight-custom';
@use 'src/styles' as *;
@use 'src/assets/styles/input-elements.scss';
@use './input-primeNG';
@use 'dropdowns';
@use 'overlay';
@use 'helpers';
@use 'clearfix';
@use 'tooltip';
@use 'buttons';
@use 'links';
@use 'src/assets/styles/tables' as tables;
@use 'src/assets/styles/globalMixins' as globalMixins;
@import 'fw-icons';
@import 'src/assets/styles/animation';
@import 'src/assets/styles/overflow';

//@import "src/assets/fonts/tillinium-webkit/stylesheet";

.mat-drawer-content {
  height: 100% !important;
  // when developing reporting set this to scroll, otherwise hidden
  overflow: hidden !important;
}

:host {
  display: block;
  height: 100%;
}

html {
  @apply scroll-smooth;
  @include globalMixins.sk-scrollbar();
}

.notify-msg {
  font-weight: 600;
  color: color(shade_2);
  font-size: $font_size_title_l;
  margin: auto;

  .icon-logo:before,
  .icon-logo-box-hex:before {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    line-height: 35px; // same as pulse animation height
  }
}

.mat-tab-group {
  font-family: Titillium Web;
}

.mat-option {
  font-family: Titillium Web !important;
}

.mat-form-field {
  line-height: 1.225em;
}

#skillhop-app .std-scrollbar {
  --scrollbar-color: #edf2ff;
  --scrollbar-thumb-color: #{color(shade_2)};
  --scrollbar-thumb-hover-color: #{color(shade_3)};
  --scrollbar-border-radius: 4px;
  --scrollbar-thickness: 6; // it's in px but the library accepts it as a number (WTF)
  --scrollbar-offset: 2; // it's the padding around the thumb in px but the library accepts it as a number (WTF)
  --scroll-view-color: transparent;
  &.wider-track {
    --scrollbar-offset: 4;
  }

  //.ng-scrollbar-track-wrapper[track='vertical'] {
  //  .ng-scroll-content {
  //    width: 100%;
  //  }
  //}
}

@mixin text-wrapper() {
  position: relative;
  vertical-align: bottom;
  //transition: 0.4s linear;

  .title-page {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 41px;
    color: color(color_primary);
    margin-top: 27px;
  }
}

.webapp-page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 38px;
  height: 100%;
  .text-wrapper {
    @include text-wrapper;
  }
  .content {
    flex: 1;
    min-height: 0;
  }
}

.custom-loading-progress-bar .mat-progress-bar-fill::after {
  background-color: color(shade_2) !important;
}

.wide-interaction-bar-wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 0 32px;
  height: 100%;
  box-sizing: border-box;
}

.wide-interaction-bar-content {
  flex: 1;
  min-height: 0;
  overflow: hidden;
}

.pure-expansion-panel {
  color: color(shade_1);
  font-family:
    Titillium Web,
    sans-serif !important;
  height: unset !important;
  mat-expansion-panel-header.mat-expansion-panel-header {
    padding-right: 0;
    padding-left: 0;
    height: unset !important;
    font-family:
      Titillium Web,
      sans-serif !important;
    font-weight: 600;
    font-size: $font_size_m;
    border-radius: 0;
    background: white !important;

    &:hover {
      background: white !important;
    }

    .expansion-panel-header-container {
      width: 100%;
      position: relative;
      .exp-panel-header-title {
        font-weight: 700;
      }

      .budget-line {
        grid-column: 2/4;
      }
    }
  }

  .expansion-panel-container {
    font-size: $font_size_m !important;
    font-family:
      Titillium Web,
      sans-serif !important;
    .date-row {
      line-height: 2px;
    }
  }
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
  .mat-expansion-panel-content {
    font-family: 'Titillium Web', sans-serif !important;
  }
}

.page-header-wrapper {
  height: 32px;
  padding: 35px 0 16px 0;
}

.page-title {
  font-weight: 700;
  font-size: $font_size_title_xl;
  line-height: 32px;
  color: color(color_primary);
}

.text-initial {
  font-size: initial;
}

// Disabled cursor
.disabled,
:disabled {
  pointer-events: none !important;
}

a.nav-link {
  text-decoration: auto;
}

.sk-disabled {
  cursor: default;
  pointer-events: none !important;
  opacity: 0.6;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-inputs {
  @apply flex items-center;
  gap: 22px;
  .input {
    min-width: 120px;
    width: 152px;
  }
}

.page-content {
  flex: 1;
  margin: 0 25px;
  overflow: hidden;
  position: relative;
}

.no-leading {
  line-height: 0;
}
